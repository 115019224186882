//@import "_functions.scss";
//@import "_variables.scss";
////@import "mixins/_breakpoints.scss";
////@import "mixins/_image.scss";
////@import "mixins/_text-truncate.scss";
//@import "mixins";
//@import "utilities/_text.scss";

@import "_functions.scss";
@import "_variables.scss";
@import "mixins";
@import "utilities";

// DAN:20190708 START //////////////////////////////////////////////////////////

// Bootstrap 4 mods

.tmi-container-breakout {
    width: 100vw !important;
    max-width: 100vw !important;
    position: relative !important;
    left: calc(-1 * (100vw - 100% + 15px) / 2) !important;
}

////////////////////////////////////////////////////////////////////////////////
// Wordpress integrations

// Overall content defs
.tmi-wp-content {
    @extend .tmi-text-grey;
    font-size: 1.25rem;
}

@include media-breakpoint-only(sm) {
    .tmi-wp-content {
        font-size: 1rem;
    }
}

@include media-breakpoint-only(xs) {
    .tmi-wp-content {
        font-size: 0.75rem;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Content alignment
.tmi-wp-content .has-text-align-left {
    @extend .text-left;
}

.tmi-wp-content .has-text-align-center {
    @extend .text-center;
}

.tmi-wp-content .has-text-align-right {
    @extend .text-right;
}

////////////////////////////////////////////////////////////////////////////////
// Headers
.tmi-wp-content h2 {
    font-size: 3rem;
    font-weight: 700;
}

.tmi-wp-content h3 {
    font-size: 2.5rem;
    font-weight: 600;
}

.tmi-wp-content h4 {
    font-size: 2rem;
    font-weight: 500;
}

@include media-breakpoint-only(sm) {
    .tmi-wp-content h2 {
        font-size: 2.5rem;
    }

    .tmi-wp-content h3 {
        font-size: 2rem;
    }

    .tmi-wp-content h4 {
        font-size: 1.5rem;
    }
}

@include media-breakpoint-only(xs) {
    .tmi-wp-content h2 {
        font-size: 2rem;
    }

    .tmi-wp-content h3 {
        font-size: 1.5rem;
    }

    .tmi-wp-content h4 {
        font-size: 1rem;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Normal quote
.tmi-wp-content blockquote.wp-block-quote {
    font-size: 3rem;
    font-weight: 300;
}

.tmi-wp-content blockquote.wp-block-quote cite {
    @extend .tmi-text-grey-light;
    font-size: 2rem;
    font-weight: 300;
}

@include media-breakpoint-only(sm) {
    .tmi-wp-content blockquote.wp-block-quote {
        font-size: 2.5rem;
    }

    .tmi-wp-content blockquote.wp-block-quote cite {
        font-size: 1.5rem;
    }
}

@include media-breakpoint-only(xs) {
    .tmi-wp-content blockquote.wp-block-quote {
        font-size: 2rem;
    }

    .tmi-wp-content blockquote.wp-block-quote cite {
        font-size: 1rem;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Fullsize images
.tmi-wp-content figure.wp-block-image.size-full,
.tmi-wp-content div.wp-block-image.size-full {
    @extend .tmi-container-breakout;
}

.tmi-wp-content figure.wp-block-image.size-full img,
.tmi-wp-content div.wp-block-image.size-full img {
    width: 100vw !important;
    max-width: 100vw !important;
    height: auto !important;
}

.tmi-wp-content figure.wp-block-image img,
.tmi-wp-content div.wp-block-image img {
    @include img-fluid;
}

.tmi-wp-content figure.wp-block-image.alignleft,
.tmi-wp-content div.wp-block-image figure.alignleft {
    float: left;
    padding: 10px 20px 0px 0px;
}

.tmi-wp-content figure.wp-block-image.aligncenter,
.tmi-wp-content div.wp-block-image figure.aligncenter {
    text-align: center;
}

.tmi-wp-content figure.wp-block-image.alignright,
.tmi-wp-content div.wp-block-image figure.alignright {
    float: right;
    padding: 10px 0px 0px 20px;
}

////////////////////////////////////////////////////////////////////////////////
// Columns
.tmi-wp-content .wp-block-columns {
    display: flex;
}

.tmi-wp-content .wp-block-columns .wp-block-column {
    flex: 1;
}

.tmi-wp-content .wp-block-columns .wp-block-column:not(:first-child) {
    padding-left: $spacer * 2;
}

////////////////////////////////////////////////////////////////////////////////
// Pull quotes
.tmi-wp-content figure.wp-block-pullquote {
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    position: relative;
    padding: 80px 60px;
    margin: 50px auto;
}

.tmi-wp-content figure.wp-block-pullquote cite {
    @extend .tmi-text-grey-light;
    font-size: 1.75rem;
    font-weight: 300;
    text-align: center;
}

.tmi-wp-content figure.wp-block-pullquote .tmi-wp-marker-tl {
    background-image: url(#{$tmAssetsPrefix}/img/wp/yellow-marker.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 5vw;
    height: 5vw;
    position: absolute;
    top: 0px;
    left: 0px;
}

.tmi-wp-content figure.wp-block-pullquote .tmi-wp-marker-br {
    background-image: url(#{$tmAssetsPrefix}/img/wp/yellow-marker.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 5vw;
    height: 5vw;
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: rotate(180deg);
}

@include media-breakpoint-only(sm) {
    .tmi-wp-content figure.wp-block-pullquote {
        font-size: 1.5rem;
        padding: 60px 40px;
        margin: 50px auto;
    }

    .tmi-wp-content figure.wp-block-pullquote cite {
        font-size: 1.5rem;
    }

    .tmi-wp-content figure.wp-block-pullquote .tmi-wp-marker-tl {
        width: 5vw;
        height: 5vw;
    }

    .tmi-wp-content figure.wp-block-pullquote .tmi-wp-marker-br {
        width: 5vw;
        height: 5vw;
    }
}

@include media-breakpoint-only(xs) {
    .tmi-wp-content figure.wp-block-pullquote {
        font-size: 1rem;
        padding: 40px 20px;
        margin: 50px auto;
    }

    .tmi-wp-content figure.wp-block-pullquote cite {
        font-size: 1rem;
    }

    .tmi-wp-content figure.wp-block-pullquote .tmi-wp-marker-tl {
        width: 5vw;
        height: 5vw;
    }

    .tmi-wp-content figure.wp-block-pullquote .tmi-wp-marker-br {
        width: 5vw;
        height: 5vw;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Basic styles

// Text colours
.tmi-text-white             { color: $tmWhite; }
.tmi-text-black             { color: $tmBlack; }
.tmi-text-yellow            { color: $tmYellow; }
.tmi-text-grey-very-light   { color: $tmGreyVeryLight; }
.tmi-text-grey-light        { color: $tmGreyLight; }
.tmi-text-grey              { color: $tmGrey; }
.tmi-text-grey-dark         { color: $tmGreyDark; }
.tmi-text-grey-very-dark    { color: $tmGreyVeryDark; }

// Text sizing
.tmi-text-small             { font-size: 0.9rem; }
.tmi-text-smaller           { font-size: 0.75rem; }

// Text weights
.tmi-text-weight-light      { font-weight: 300; }
.tmi-text-weight-medium     { font-weight: 500; }
.tmi-text-weight-semibold   { font-weight: 600; }
.tmi-text-weight-bold       { font-weight: 700; }

// Background colours
.tmi-bg-white               { background-color: $tmWhite; }
.tmi-bg-black               { background-color: $tmBlack; }
.tmi-bg-yellow              { background-color: $tmYellow; }
.tmi-bg-grey-very-light     { background-color: $tmGreyVeryLight; }
.tmi-bg-grey-light          { background-color: $tmGreyLight; }
.tmi-bg-grey                { background-color: $tmGrey; }
.tmi-bg-grey-dark           { background-color: $tmGreyDark; }
.tmi-bg-grey-very-dark      { background-color: $tmGreyVeryDark; }

.tmi-font-weight-light      { font-weight: 300; }
.tmi-font-weight-medium     { font-weight: 500; }
.tmi-font-weight-semibold   { font-weight: 600; }
.tmi-font-weight-bold       { font-weight: 700; }

////////////////////////////////////////////////////////////////////////////////
// Utility styles

.tmi-offscreen {
    position: absolute;
    left: -9999px;
}

.tmi-text-shadow {
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.tmi-text-no-hover:hover {
  color: inherit !important;
}

////////////////////////////////////////////////////////////////////////////////
// Header styles
.tmi-header-link-primary {
    color: $tmWhite;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    transition: color 0.3s;
}

.tmi-header-link-secondary {
    color: $tmWhite;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.75rem;
    letter-spacing: 1px;
    transition: color 0.3s;
}

.tmi-header-link-primary:hover,
.tmi-header-link-secondary:hover {
    color: $tmYellow;
    text-decoration: none;
}

////////////////////////////////////////////////////////////////////////////////
// Header (nav) styles
.tmi-header-nav-section,
.tmi-header-nav-section > a {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: $tmGrey;
    font-weight: 300;
    letter-spacing: 2px;
}

.tmi-header-nav-section > a {
    transition: color 0.3s;
}

.tmi-header-nav-section-selected {
    font-weight: 700 !important;
}

.tmi-header-nav-section > a:hover {
    text-decoration: none;
    color: $tmGreyVeryDark;
}

.tmi-header-nav-section.smaller,
.tmi-header-nav-section.smaller > a {
    font-size: 0.8rem;
}

////////////////////////////////////////////////////////////////////////////////
// Footer styles
.tmi-footer a {
    color: $tmWhite;
    font-weight: 300;
    transition: color 0.3s;
}

.tmi-footer a:hover {
    text-decoration: none;
    color: $tmYellow;
}

////////////////////////////////////////////////////////////////////////////////
// Debugging styles

.tmi-debug-env {
    border: dashed 2px $red;
    background: $tmGrey;
    color: $tmWhite;
    position: fixed;
    bottom: 10px;
    left: 10px;
}

////////////////////////////////////////////////////////////////////////////////
// General HTML overrides

html {
    background-color: $tmGreyDark;
}

a {
    transition: color 0.3s
}

////////////////////////////////////////////////////////////////////////////////
// Bootstrap override styles
// (only overrides that can't be done in the scss files without hacking the core are here)

// Mobile navbar formatting
.navbar-nav > .nav-item > .nav-link {
    font-size: 0.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 300;
    margin-left: $spacer / 2;
}

.navbar-nav > .dropdown-divider {
    border-top-color: $tmGrey;
}

// Carousel indicators to appear below slide (not on top of)
.carousel-indicators {
    bottom: $spacer * -3;
}

////////////////////////////////////////////////////////////////////////////////
// Homepage

.tmi-home-intro-search-form {
    width: 100%;
}

.tmi-home-intro-search-search {
    font-size: 1.5rem;
    font-weight: 300;
    background: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
}

.tmi-home-intro-search-search-icon > i {
    font-size: 3rem;
}

@include media-breakpoint-up(sm) {
    .tmi-home-intro-search-search {
        font-size: 2rem;
    }

    .tmi-home-intro-search-search-icon > i {
        font-size: 4rem;
    }
}

@include media-breakpoint-up(lg) {
    .tmi-home-intro-search-container-large {
        display: block;
    }

    .tmi-home-intro-search-container-small {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    .tmi-home-intro-search-container-large {
        display: none;
    }

    .tmi-home-intro-search-container-small {
        display: block;
    }
}

////////////////////////////////////////////////////////////////////////////////
// TM Card Square

.tmi-card,
.tmi-card-square {
    // (.d-flex)
    display: flex !important;

    // (.flex-column)
    flex-direction: column !important;

    width: 100%;
    background-size: cover;
    background-position: top center;
    background-image: url(#{$tmAssetsPrefix}/img/loading-1024w.png);
    background-repeat: no-repeat;
}

.tmi-card > div > span.title,
.tmi-card-square > div > span.title {
    background: transparent;
    padding: $spacer/2 $spacer $spacer/2 $spacer;
    border-radius: 3px;
    color: $tmWhite;
    font-weight: 500;
    margin: 0 auto;
    font-size: 3vw;
    transition: background-color 0.3s;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.tmi-card > div > span.hover-hint,
.tmi-card-square > div > span.hover-hint {
    padding: $spacer / 2;
    color: white;
    font-weight: 500;
    margin: 0 auto;
    font-size: 2vw;
    border-bottom: solid 3px $tmYellow;
    opacity: 0;
    transition: opacity 0.3s;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

@include media-breakpoint-down(md) {
    .tmi-card > div > span.title,
    .tmi-card-square > div > span.title {
        font-size: 9vw;
    }

    .tmi-card > div > span.hover-hint,
    .tmi-card-square > div > span.hover-hint {
        font-size: 6vw;
    }
}

.tmi-card:hover > div > span.title,
.tmi-card-square:hover > div > span.title
{
    background: rgba($tmGreyDark, 0.8);
}

.tmi-card:hover > div > span.hover-hint,
.tmi-card-square:hover > div > span.hover-hint
{
    opacity: 1;
}

////////////////////////////////////////////////////////////////////////////////
// TM back to top link

.tmi-back-to-top {
    @extend .tmi-text-grey-dark, .tmi-text-weight-bold;
    border-bottom: 3px solid $tmYellow;
    padding: $spacer $spacer*2;
}

.tmi-back-to-top-conditional {
    display: none;
}

////////////////////////////////////////////////////////////////////////////////
// TM read more link
.tmi-read-more {
    @extend .tmi-text-grey-dark, .tmi-text-weight-bold;
    border-bottom: 3px solid $tmYellow;
    padding: $spacer $spacer*2;
    text-transform: uppercase;
}

.tmi-newsletter-subscribe-recaptcha-container .g-recaptcha {
    display: none;
}

////////////////////////////////////////////////////////////////////////////////
// TM images result

//.tmi-images-result-container-outer {
//}

.tmi-images-result-new {
}

.tmi-images-result-container-inner {
  //height: 100vw;
}

.tmi-images-result-container-inner {
  transition: background-color 0.3s;
}

.tmi-images-result-container-inner .tmi-images-result-info-container {
  position: absolute;
  bottom: 0px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tmi-images-result-container-inner:hover {
  background-color: lighten($tmGreyLight, 10%);
}

.tmi-images-result-container-inner:hover .tmi-images-result-info-container {
  //background-color: opacify(lighten($tmGreyLight, 10%), 0.9);
  background: lighten($tmGreyLight, 10%);
  //background: linear-gradient(0deg, lighten($tmGreyLight, 10%) 0%, lighten($tmGreyLight, 10%) 75%, rgba(255,255,255,0) 100%);
  background: linear-gradient(0deg, lighten($tmGreyLight, 10%) 0%, lighten($tmGreyLight, 10%) 75%, rgba(lighten($tmGreyLight, 10%), 0) 100%);
  opacity: 1;
}

.tmi-images-result-container-inner-row {
  position: relative;
  height: 100%;
}

// (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.tmi-grid-size-small .tmi-images-result-container-inner {
  //height: 109vw;
}

.tmi-grid-size-small .tmi-images-result-thumbnail {
  //height: 72vw;
}

// (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.tmi-grid-size-medium .tmi-images-result-container-inner {
  //height: 109vw;
}

.tmi-grid-size-medium .tmi-images-result-thumbnail {
  //height: 72vw;
}

// (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.tmi-grid-size-large .tmi-images-result-container-inner {
  //height: 110vw;
}

.tmi-grid-size-large .tmi-images-result-thumbnail {
  //height: 72vw;
}

/*
// SM --------------------------------------------------------------------------
@include media-breakpoint-only(sm) {
  // (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-small .tmi-images-result-container-inner {
    height: 72vw;
  }

  .tmi-grid-size-small .tmi-images-result-thumbnail {
    height: 35vw;
  }

  // (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-medium .tmi-images-result-container-inner {
    height: 97vw;
  }

  .tmi-grid-size-medium .tmi-images-result-thumbnail {
    height: 65vw;
  }

  // (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-large .tmi-images-result-container-inner {
    height: 110vw;
  }

  .tmi-grid-size-large .tmi-images-result-thumbnail {
    height: 75vw;
  }
}

// MD --------------------------------------------------------------------------
@include media-breakpoint-only(md) {
  // (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-small .tmi-images-result-container-inner {
    height: 59vw;
  }

  .tmi-grid-size-small .tmi-images-result-thumbnail {
    height: 35vw;
  }

  // (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-medium .tmi-images-result-container-inner {
    height: 60vw;
  }

  .tmi-grid-size-medium .tmi-images-result-thumbnail {
    height: 35vw;
  }

  // (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-large .tmi-images-result-container-inner {
    height: 95vw;
  }

  .tmi-grid-size-large .tmi-images-result-thumbnail {
    height: 70vw;
  }
}

// LG --------------------------------------------------------------------------
@include media-breakpoint-only(lg) {
  // (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-small .tmi-images-result-container-inner {
    height: 46vw;
  }

  .tmi-grid-size-small .tmi-images-result-thumbnail {
    height: 27vw;
  }

  // (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-medium .tmi-images-result-container-inner {
    height: 42vw;
  }

  .tmi-grid-size-medium .tmi-images-result-thumbnail {
    height: 20vw;
  }

  // (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-large .tmi-images-result-container-inner {
    height: 90vw;
  }

  .tmi-grid-size-large .tmi-images-result-thumbnail {
    height: 70vw;
  }
}

// XL --------------------------------------------------------------------------
@include media-breakpoint-only(xl) {
  // (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-small .tmi-images-result-container-inner {
    height: 37vw;
  }

  .tmi-grid-size-small .tmi-images-result-thumbnail {
    height: 21vw;
  }

  // (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-medium .tmi-images-result-container-inner {
    height: 40vw;
  }

  .tmi-grid-size-medium .tmi-images-result-thumbnail {
    height: 25vw;
  }

  // (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-large .tmi-images-result-container-inner {
    height: 57vw;
  }

  .tmi-grid-size-large .tmi-images-result-thumbnail {
    height: 40vw;
  }
}

// XXL -------------------------------------------------------------------------
@include media-breakpoint-only(xxl) {
  // (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-small .tmi-images-result-container-inner {
    height: 24vw;
  }

  .tmi-grid-size-small .tmi-images-result-thumbnail {
    height: 14vw;
  }

  // (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-medium .tmi-images-result-container-inner {
    height: 35vw;
  }

  .tmi-grid-size-medium .tmi-images-result-thumbnail {
    height: 25vw;
  }

  // (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-large .tmi-images-result-container-inner {
    height: 51vw;
  }

  .tmi-grid-size-large .tmi-images-result-thumbnail {
    height: 40vw;
  }
}

// XXXL ------------------------------------------------------------------------
@include media-breakpoint-only(xxxl) {
  // (small) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-small .tmi-images-result-container-inner {
    height: 23vw;
  }

  .tmi-grid-size-small .tmi-images-result-thumbnail {
    height: 15vw;
  }

  // (medium) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-medium .tmi-images-result-container-inner {
    height: 36vw;
  }

  .tmi-grid-size-medium .tmi-images-result-thumbnail {
    height: 28vw;
  }

  // (large) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .tmi-grid-size-large .tmi-images-result-container-inner {
    height: 53vw;
  }

  .tmi-grid-size-large .tmi-images-result-thumbnail {
    height: 45vw;
  }
}
*/

////////////////////////////////////////////////////////////////////////////////

.nav-link {
  color: $tmWhite;
}

.nav-link.active {
  border: solid 1px $tmWhite;
}


hr.tmi-primary {
  border-top-color: $primary;
}

hr.tmi-secondary {
  border-top-color: $secondary;
}

// DAN:20190708 END ////////////////////////////////////////////////////////////
